

































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiAddTemplate,
    apiEditTemplate,
    apiTemplateDetail
} from '@/api/application/print'
import {
    PaymentConfig_Req,
    PaymentConfigGet_Req
} from '@/api/setting/payment.d'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
    components: {
        MaterialSelect
    }
})
export default class PrintEdit extends Vue {
    /** S Data **/

    identity: Number = 1

    // 打印机设置的数据
    printData: any = {
        template_name: '', //	是	string	模板名称
        ticket_name: '', //	是	string	小票名称
        show_shop_name: 1, //	是	integer	是否显示商城名称 0-否 1-是
        template_id: 0, //	是	integer	模板id
        selffetch_shop: {
            shop_name: 1,
            contacts: 1,
            shop_address: 1
        }, //	是	object	自提门店信息
        show_buyer_message: 1, //	是	integer	是否显示买家留言 0-否 1-是
        consignee_info: {
            name: 1,
            mobile: 1,
            address: 1
        }, //	是	object	收货人信息
        show_qrcode: 1, //	是	integer	是否显示二维码 0-否 1-是
        bottom: '谢谢惠顾，欢迎下次光临', //	是	string	底部信息
        qrcode_name: '', //	否	string	二维码名称
        qrcode_content: '' //	否	string	二维码内容
    }

    // 表单验证
    rules: any = {
        template_name: [
            { required: true, message: '请输入打印机名称', trigger: 'blur' }
        ],
        ticket_name: [
            { required: true, message: '输入小票名称', trigger: 'blur' }
        ],
        bottom: [
            { required: true, message: '请输入底部信息', trigger: 'blur' }
        ],
        qrcode_content: [
            { required: true, message: '请输入二维码链接', trigger: 'blur' }
        ],
        qrcode_name: [
            { required: true, message: '请输入二维码名称', trigger: 'blur' }
        ]
    }

    /** E Data **/

    /** S Methods **/
    async getTemplateDetail() {
        const res = await apiTemplateDetail({ id: this.identity })
        this.printData = res
    }

    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) {
                return
            }
            if (!this.identity) {
                this.handleTemplateAdd()
            } else {
                this.handleTemplateEdit()
            }
        })
    }

    // 添加模版
    async handleTemplateAdd() {
        const params = this.printData
        await apiAddTemplate({ ...params })
        this.$router.back()
    }

    // 编辑模版
    handleTemplateEdit() {
        const params = this.printData
        const id: number = this.identity as number
        apiEditTemplate({ ...params, id })
            .then(() => {
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch(() => {
                this.$message.error('编辑失败!')
            })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        this.identity = query.id
        if (this.identity) {
            this.getTemplateDetail()
        }
    }
    /** E Life Cycle **/

    get handleShopInfo() {
        const arr = []
        const print = this.printData.selffetch_shop
        for (const key in print) {
            arr.push(print[key])
        }
        const res = arr.find(item => item == 1)
        return res == undefined ? false : true
    }

    get handleUserInfo() {
        const arr = []
        const print = this.printData.consignee_info
        for (const key in print) {
            arr.push(print[key])
        }
        const res = arr.find(item => item == 1)
        return res == undefined ? false : true
    }
}
